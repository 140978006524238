export const Conditions = () => {
  const headingStyle = {
    fontSize: '18px',
    fontStyle: 'italic',
    fontFamily: 'Times New Roman,arial',
    fontWeight: 'bold',
  }
  const fnote = {
    fontSize: '15px',
    fontFamily: 'italic',
    // paddingLeft: '50px',
  }
  const Juniorstyle = {
    float: 'right',
    marginTop: '15px',
    fontSize: '15px',
    fontWeight: 'bold',
  }
  return (
    <div className='Conditions'>
      <h6>
        <u>Conditions</u>
      </h6>
      <ol>
        <li>
          <small>
            Vegetarian food shall be provided to the trekkers at Athirumala Base
            Camp as part of the package. Details are given below. Aadhaar Food
            Item
          </small>
        </li>
        <br />
        <table class='table table-bordered'>
          <thead>
            <th scope='col'>Sl.No.</th>
            <th scope='col'>Time</th>
            <th scope='col'>Food Item</th>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>Day - 1</td>
              <td>Evening(Tea/Coffee & Snacks), Dinner (Kanji)</td>
            </tr>
            <tr>
              <td>2</td>
              <td>Day - 2</td>
              <td>
                Morning (Black Tea/ Coffee), Breakfast (Puttu/Puri + Curry),
                Lunch(Meals), Evening(Black Tea/ Coffee & Snacks), Dinner
                (Kanji)
              </td>
            </tr>
            <tr>
              <td>3</td>
              <td>Day - 3</td>
              <td>
                Morning (Black Tea/ Coffee), Breakfast (Puttu/Puri + Curry)
              </td>
            </tr>
          </tbody>
        </table>
        <li>
          <small>
            Since the programme involves trekking through dense forests abundant
            in wildlife, undulated rugged terrain, hazardous and slippy rock
            formations, trekkers nced to bring trekking shoes and minimum
            luggage. No poojas are allowed at the Agasthyarkoodam. Intoxicants
            of any sort are strictly prohibited.
          </small>
        </li>
        <li>
          <small>
            <b>
              Medical fitness certificate obtained within one week from the date
              of Trekking from a registered Medical Practitioner of Modern
              Medieine (Allopathy) should be produced at the time of trekking;
              failing which permission cannot be granted.
            </b>
          </small>
        </li>
        <li>
          <small>
            . All the trekkers have to compulsory submit the "Consent" form at
            Bonacord Picket Station before commencing the trekking.
          </small>
        </li>
        <li>
          <small>
            On failing to turn up for the trek on the assigned date, the
            trekkers are not eligible for refund or swapping of individuals.
            However, in case of such an exigency, the trekkers can include new
            members by paying additional amount i.e. Rs.4,000/- per person
          </small>
        </li>
        <li>
          <small>
            {' '}
            Do not litter pristine forests with non-biodegradalble materials
            like plastic bags cups of bottles or any other materials which may
            pose danger to the wildlife.
          </small>
        </li>
        <li>
          <small>
            Extra care needs to be taken during the trek which involves crossing
            of rivers and streams. Kerala Forest Department will not be liable
            for accidents of any kind injury during the trekking programme
          </small>
        </li>
        <li>
          <small>
            {' '}
            The original ID Cards should be produced for verification at the
            Bonacord Picket Station on the first day of Trekking.
          </small>
        </li>
        <li>
          <small>
            Trekkers need to strictly follow the Do's and Don'ts during the trek
            and violation of any kind will attract penal provisions laid out by
            the Kerala Forest Act, 1961 and Wildlife Protection Act, 1972.
          </small>
        </li>
        <li>
          <small>
            . No special provisions/ facilities will be available for women
            trekkers including the service of female ecotourism guides or forest
            personnel during the 'special package'. Safety and protection of the
            women folk are the sole responsibility of their team members.
          </small>
        </li>
        <li>
          <small>
            {' '}
            Trekkers need to report at Forest Check post Kanithadam enroute to
            Bonacord before 9 am on the date assigned.
          </small>
        </li>
        <li>
          <small>
            Duration of the Trekking is three days and the trekkers should
            scrupulously follow the instructions of ecotourism guides/ forest
            personnel during the trekking programme.
          </small>
        </li>
        <li>
          <small>
            Kerala Forest Department is not liable nor will refund the money
            paid if the trekkers fail to complete the trek due to inclement
            weather or natural vagaries.
          </small>
        </li>
        <li>
          <small>
            Wildlife Warden, Thiruvananthapuram is empowered to defer or cancel
            any permission given for trekking to Agasthyarkoodam or modify
            conditions laid out without giving prior intimation /reasons and his
            decision would be final.
          </small>
        </li>
        <li>
          <small>
            Trekking shall be allowed depending upon the climatic conditions.
          </small>
        </li>
        <li>
          <small>Playing music while trekking is prohibited.</small>
        </li>
        <li>
          <small>
            Every five member group of Trekkers shall be accompanied by Two
            Guides and Ten member group of Trekkers shall be accompanied by
            three guides
          </small>
        </li>
        <li>
          <small>
            For details/queries, if any, trekkers may seek assistance from
            8547602954, 8547602948 or 04712360762.
          </small>
        </li>
      </ol>

      <div className='queries'>
        {/* <div>
          <h5 style={headingStyle}>
            <b>Approved For issue</b>
          </h5>
          <br />

          <p style={Juniorstyle}>
            Junior Superintendent
            <br />
            <br />
          </p>
        </div> */}

        <div className='saveFRT'>
          <small>
            Sd/-
            <br /> Wildlife Warden
            <br /> PTP Nagar,Thiruvananthapuram
          </small>
          <h4>"SAVE FOREST..... SAVE WATER"</h4>
        </div>
      </div>
      <small style={fnote}>
        Copy to Asst. Wildlife Warden, Peppara for information and necessary
        action
      </small>
    </div>
  )
}
