export const Declaration = () => {
  return (
    <div className='declaration'>
      <h3>
        <b>
          Kerala Forest & Wildlife Department Neyyar - Peppara Forest
          Development Agency
        </b>
      </h3>
      <h2>DECLARATION</h2>
      <h2>
        (Declaration given for participating in the programme conducted by FDA
        in association with Kerala Forest Department)
      </h2>
      <p>
        I do hereby declare that I am fully aware of the details of this
        programme and of all possible hazards of walking in to the forest of.
        Thiruvananthapuram Wildlife Division
      </p>
      <p>
        I also declare that I am confident of my health condition to undertake
        the walk of tedious nature and nobody else will be responsible for any
        injuries or causalities. I am not entitled to claim any compensation
        from the EDC or from the Kerala Forest Department, for any damages to my
        personal health and personal belongings while participating in the
        programme (Agasthyarkoodam Trekking) of Bonacaud EDC
      </p>
      <div className='fluid'>
        <table className='table table-bordered'>
          <thead>
            <tr>
              <th>SL No</th>
              <th>Name & Address</th>
              <th>ID Number</th>
              <th>M/F</th>
              <th>Age</th>
              <th>Phone Number</th>
              <th>Signature</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>2</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>3</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>4</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>5</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>6</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>7</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>8</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>9</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>10</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}
