import React, { useEffect, useState } from 'react'
import './profile.scss'
import banner from '../../../Assets/Images/inner-banner/inner-banner2.jpg'
import Avatar from '../../../Assets/Images/profile.png'
import { toast } from 'react-toastify'
import Loading from '../../Layouts/Loading'
import { updateProfileSerivce } from '../../../Services/BaseApiPostService'
import { getuserdetails } from '../../Layouts/UserService'

export const Profile = () => {
  const [isEdit, setisEdit] = useState(false)
  const [isLoading, setisLoading] = useState(false)
  const [userDetail, setuserDetail] = useState('')
  const [name, setName] = useState('')
  const [phoneNumber, setphoneNumber] = useState('')

  useEffect(() => {
    getuserdetails(setuserDetail)
  }, [isLoading])

  const updateProfile = async () => {
    setisLoading(true)

    // Validate phone number format
    const phoneRegex = /^[0-9]{10}$/
    if (!phoneRegex.test(phoneNumber)) {
      setisLoading(false)
      toast.error('Please enter a valid phone number.')
      return
    }

    const dataForm = {
      name: name,
      phone: phoneNumber,
    }

    const res = await updateProfileSerivce(dataForm)

    if (res.data.status) {
      setisLoading(false)
      toast.success(res.data.message)
      setisEdit(false)
      // window.location.reload(false);
    } else {
      setisLoading(false)
      toast.error(res.data.message)
    }
  }

  const editProfileSection = () => {
    setisEdit(true)
    setName(userDetail?.name)
    setphoneNumber(userDetail?.phone)
  }

  return (
    <>
      {userDetail === '' ? (
        <Loading />
      ) : (
        <div className='profileConatiner'>
          <div className='profileBanner'>
            <img src={banner} alt='banner' />
          </div>
          <div className='profileMainSection'>
            <div className='profileConatiner'>
              <div className='profileImageSection'>
                <div className='imgConatinerprofile'>
                  <img src={Avatar} alt='profilepic' />
                </div>
                <div className='userdetailsProfile'>
                  <h4>{userDetail?.name}</h4>
                  <p>{userDetail?.email}</p>
                </div>
              </div>
            </div>
            <div className='profiledetailsconatiner'>
              {isLoading ? (
                <div className='loadingMainSection' style={{ height: '150px' }}>
                  <div className='loader'></div>
                </div>
              ) : isEdit ? (
                <div className='mainConatinerProfileSection'>
                  <div>
                    <label htmlFor=''>Name</label>
                    <input
                      type='text'
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value)
                      }}
                    />
                  </div>
                  <div>
                    <label htmlFor=''>Phone</label>
                    <input
                      type='number'
                      value={phoneNumber}
                      onChange={(e) => {
                        setphoneNumber(e.target.value)
                      }}
                    />
                  </div>
                  <div>
                    <label htmlFor=''>Email</label>
                    <p>{userDetail.email}</p>
                  </div>
                  <div>
                    <button
                      className='mainButton'
                      onClick={() => updateProfile()}
                    >
                      Update Profile
                    </button>
                  </div>
                </div>
              ) : (
                <div className='mainConatinerProfileSection'>
                  <div>
                    <label htmlFor=''>Name</label>
                    <p>{userDetail?.name}</p>
                  </div>
                  <div>
                    <label htmlFor=''>Phone</label>
                    <p>{userDetail?.phone}</p>
                  </div>
                  <div>
                    <label htmlFor=''>Email</label>
                    <p>{userDetail?.email}</p>
                  </div>
                  <div>
                    <button
                      className='mainButton'
                      onClick={() => editProfileSection()}
                    >
                      Edit Profile
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  )
}
