import React from 'react'
import './style.scss'
import keralaLogo from '../../../Assets/Images/keralaLogo.png'
import QRCode from 'react-qr-code'
import moment from 'moment/moment'
import { MedicalCertificate } from './MedicalCertificate'
import { Declaration } from './Declaration'
import { Affidavit } from './Affidavit'
import { Conditions } from './Conditions'

export const PrintScreen = ({ bookingSummaryinfo }) => {
  if (bookingSummaryinfo === '{}') {
    // console.log("not work in print");
  }

  return (
    <div className='bookingPrint' id='printablediv'>
      <div className='headerSection'>
        <div className='logoSection'>
          <div className='logoImg'>
            <img src={keralaLogo} alt='' />
          </div>
          <small>
            KERALA FORESTS AND WILDLIFE DEPARTMENT <br />
            {bookingSummaryinfo?.main_data?.pkg_name}
          </small>
        </div>
        <div className='qrSection'>
          <QRCode
            size={256}
            style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
            value={bookingSummaryinfo?.main_data?._id.toString() || 'null'}
            viewBox={`0 0 256 256`}
          />
        </div>
      </div>
      <div className='bookingDetails'>
        <p>
          {/* <span>No.</span>: ORAV/2022/00053 */}
          <span>No.:</span> {bookingSummaryinfo?.main_data?.booking_id}
        </p>

        {bookingSummaryinfo?.main_data?.type === 'Trekking' ? (
          <p>
            <span>Booking Date:</span>
            {/* {bookingSummaryinfo?.main_data?.start_date} */}
            {moment(bookingSummaryinfo?.main_data?.createdAt).format(
              'DD-MM-YYYY'
            )}
          </p>
        ) : (
          <p>
            <span>Booking Date:</span>
            {/* {bookingSummaryinfo?.main_data?.start_date} -
            {bookingSummaryinfo?.main_data?.end_date} */}
            {moment(bookingSummaryinfo?.main_data?.createdAt).format(
              'DD-MM-YYYY'
            )}
          </p>
        )}
      </div>
      <div className='tableSection'>
        <table class='table table-bordered'>
          <thead>
            {bookingSummaryinfo?.main_data?.type === 'Trekking' ? (
              <tr>
                <th scope='col'>Sl.No.</th>
                <th scope='col'>Name</th>
                <th scope='col'>Age</th>
                <th scope='col'>Gender</th>
                <th scope='col'>Mobile Number</th>
                <th scope='col'>Nationality</th>
                <th scope='col'>Photo ID Type</th>
                <th scope='col'>Photo ID No.</th>
                <th scope='col'>Insurance No.</th>
                <th scope='col'>Group Leader?</th>
              </tr>
            ) : (
              <tr>
                <th scope='col'>Sl.No.</th>
                <th scope='col'>Name</th>
                <th scope='col'>Mobile Number</th>
                <th scope='col'>Email</th>
                <th scope='col'>Number of Adult</th>
                <th scope='col'>Number of Child</th>
              </tr>
            )}
          </thead>
          <tbody>
            {bookingSummaryinfo?.main_data?.type === 'Trekking' ? (
              bookingSummaryinfo?.details?.map((userDetails, index) => (
                <tr>
                  <th>{index + 1}</th>
                  <td>{userDetails.name}</td>
                  <td>{userDetails.age}</td>
                  <td>{userDetails.gender}</td>
                  <td>{userDetails.mobile}</td>
                  <td>{userDetails.user_category}</td>
                  <td>{userDetails.id_proof_type}</td>
                  <td>{userDetails.id_proof_number}</td>
                  <td>{userDetails.insurance_number}</td>
                  <td>{userDetails.is_group_leader ? 'Yes' : '-'}</td>
                </tr>
              ))
            ) : (
              <tr>
                <th>1</th>
                <td>{bookingSummaryinfo?.main_data?.user_name}</td>
                <td>{bookingSummaryinfo?.main_data?.phone}</td>
                <td>{bookingSummaryinfo?.main_data?.email}</td>
                <td>{bookingSummaryinfo?.main_data?.adult_count}</td>
                <td>{bookingSummaryinfo?.main_data?.child_count}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className='bookingDatas'>
        <div>
          {bookingSummaryinfo?.main_data?.type === 'Stay' ? (
            <div className='row'>
              <p>
                <span>Check-in Date</span>:{' '}
                {bookingSummaryinfo?.main_data?.start_date}
              </p>
              <p>
                <span>Check-out Date</span>:{' '}
                {bookingSummaryinfo?.main_data?.end_date}
              </p>
              <p>
                <span>Number of Rooms</span>:{' '}
                {bookingSummaryinfo?.main_data?.no_of_rooms}
              </p>
            </div>
          ) : (
            <p>
              <span>Date of journey</span>:{' '}
              {bookingSummaryinfo?.main_data?.start_date} <br /> (This entry
              pass is valid for
              <span className='bookingdatesView'>
                {bookingSummaryinfo?.main_data?.start_date}
              </span>
              only.)
            </p>
          )}
          <p>
            <span>TOTAL AMOUNT Rs</span>:{' '}
            {bookingSummaryinfo?.main_data?.total_amount}
          </p>
        </div>
        <br></br>
        {bookingSummaryinfo?.main_data?.type !== 'Stay' ? (
          <div className='reportSection'>
            <p>
              {bookingSummaryinfo?.main_data?.type === 'Ticket' ? (
                <span>Opening Time:</span>
              ) : (
                <span>Reporting Time:</span>
              )}{' '}
              {bookingSummaryinfo?.main_data?.reporting_time}
            </p>
            <p>
              <span>Reporting Place:</span>
              {bookingSummaryinfo?.main_data?.reporting_place}
            </p>
          </div>
        ) : (
          ''
        )}
        {bookingSummaryinfo?.main_data?.type === 'Trekking' ? (
          <div>
            <div>
              <small>
                All Trekkers must display their original photo id before
                entering the Agastyaarkodam on the Trekking date (
                {bookingSummaryinfo?.main_data?.start_date})<br />
                Entry pass shall be valid only if the affidavit has been signed
                by the group leader/applicant.
              </small>
            </div>
            {/* <div>
              <small>
                Entry pass shall be valid only if the affidavit has been signed
                by the group leader/applicant.
              </small>
            </div> */}
          </div>
        ) : (
          ''
        )}
      </div>
      <div className='queries'>
        <div>
          <h6>For Queries, please contact:</h6>
          <p>Wildlife Warden,</p>
          <p>PTP Nagar, Thiruvananthapuram</p>
          <p>Ph: 0471 2360762</p>
        </div>
        <div className='saveFRT'>
          <h4>"SAVE FOREST..... SAVE WATER"</h4>
          <p>Facilitated by FMIS Wing</p>
        </div>
      </div>

      {bookingSummaryinfo?.main_data?.pkg_id === '65b8b8851018cf138a40301c' && (
        // {bookingSummaryinfo?.main_data?.pkg_id === '6601b988dac54bb2f7f40054' && (
        <Conditions />
      )}
      {bookingSummaryinfo?.main_data?.pkg_id === '65b8b8851018cf138a40301c' && (
        // {/* {bookingSummaryinfo?.main_data?.pkg_id === '6601b988dac54bb2f7f40054' && ( */}
        <Affidavit />
      )}
      {bookingSummaryinfo?.main_data?.pkg_id === '65b8b8851018cf138a40301c' && (
        // {bookingSummaryinfo?.main_data?.pkg_id === '6601b988dac54bb2f7f40054' && (
        <MedicalCertificate />
      )}
      {bookingSummaryinfo?.main_data?.pkg_id === '65b8b8851018cf138a40301c' && (
        // {/* {bookingSummaryinfo?.main_data?.pkg_id === '6601b988dac54bb2f7f40054' && ( */}
        <Declaration />
      )}
    </div>
  )
}
