export const Affidavit = () => {
  return (
    <div className='affidavitConatiner'>
      <div className='text-center'>
        <h1>Affidavit</h1>
      </div>
      <div className='affidavitContent'>
        <p style={{ marginLeft: '30px' }}>
          I ________________________________S/o ___________________________
          residing at ______________________________________ undertake as
          follows:
        </p>
        <ol>
          <li>
            I and my team members will abide by the rules and regulations of
            Kerala Forests and Wildlife Department and all instructions issued
            by the Forest staff and guides.
          </li>
          <li>
            Kerala Forests and Wildlife Department can initiate action against
            us, in case of violation of any instructions during the trekking
            programme.
          </li>
          <li>
            I/We are fully aware of the risk involved in the trekking programme
            including attacks from wild animals and health problems which may
            develop during trekking.
          </li>
          <li>
            I/We am/are undertaking trekking programme on our own responsibility
            and Kerala Forests and Wildlife Department and Eco Development
            Committee will not be held responsible for any loss/damage to life
            or property of any of us.
          </li>
          <li>
            I/We do swear that no plastic carry bags/bottles/utensils/food
            stuffs will be carried to the forest during the trekking and that no
            cooking/ Consumption of Liquor will be attempted. Dated this the day
            of January/February 20
          </li>
        </ol>
      </div>

      <div className='affidavitSign'>
        <div className='sign1'>
          <p>Place:</p>
          <p>Forest Picket Station, Bonacaud</p>
        </div>
        <div>
          <p>Sign:</p>
          <p>Name of Team Leader: </p>
        </div>
      </div>
      <div className='affidavitfooter'>
        <h6>For Queries, please contact:</h6>
        <p>Wildlife Warden,</p>
        <p>PTP Nagar, Thiruvananthapuram</p>
        <p>Ph: 0471 2360762</p>
      </div>
    </div>
  )
}
