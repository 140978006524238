export const MedicalCertificate = () => {
  return (
    <div class='containeri'>
      <h1>
        <u>MEDICAL CERTIFICATE OF FITNESS</u>
      </h1>
      <p>
        I have examined Shri/Kumari/Smt……………………………………………………………………………………………………………………
         Son/Daughter of Shri …………………………………………………………………………………………… aged…………. Years, of
        Village ………………………………………………………………… P. O…………………… …………………………… P.S
        ………………………Dist…………………………………. State……………………………………………… PIN ………………………… and
        certify that, he/ she is free from deafness, defective vision (including
        colour vision) or any other infirmity, mental or physical, likely to
        interfere with the efficiency of his/ her work and found him/ her
        possessing good health.
      </p>
      <p>
        <br />
        I/ We also certify that he/ she has marks of small pox/ Vaccination.
      </p>

      <ul>
        <li>
          <span className='dot'></span>
          <div className='signature-details'>
            Chest measurement in
            <span class='details'>
              On full Expiration
              <br />
              On full inspiration
              <br />
              Difference (expansion)
            </span>
          </div>
        </li>
        <li>
          <span className='dot'></span>Weight in kg.
        </li>
        <li>
          <span className='dot'></span>Cardio – Vascular system.
        </li>
        <li>
          <span className='dot'></span>Respiratory System.
        </li>
        <li>
          <span className='dot'></span>His/ Her vision is normal.
        </li>
        <li>
          <span className='dot'></span>
          <li class='hypermetropic-mypopic'>Hypermetropic/ Mypopic.</li>
          <li class='astigmatic-margin'>Astigmatic</li>
        </li>
        <li>
          (here enter the degree of defect and the strength of correction
          glasses)
        </li>
        <br />
        <br />
      </ul>
      <p className='bold-text'>
        <br />
        This certificate is being given to him/ her for the purpose of Trekking
        for 3 days to Agasthyarkoodam.
      </p>
      <p className='candidate-sign'>
        Signature of Candidate
        <br /> (To be signed in presence of the Medical Officer)
      </p>
      <div class='signature'>
        <p>Signature of Medical Officer: ……………………………………………</p>
        <p>Name of Medical Officer: Dr. ………………………………………………</p>
        <p>Registration No: ……………………………………………………………………………</p>
        <p>Rank: ………………………………………………………………………………………………………</p>
      </div>
      <h6 className="container">
        <span class='dated'> Dated: </span>
        <span class='seal'>Seal</span>
      </h6>
    </div>
  )
}
